





























































































































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'ChemMcM1AA3Lab7Results',
  components: {
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        concAceticAcid: null,
        concAcetate: null,
        pI: null,
        targetPH: null,
        pKa: null,
        volAceticAcid: null,
        volAcetate: null,
        explanationOfAceticAcidAndAcetateAmounts: null,
        measuredPH: null,
        pctError: null,
        errorAnalysis: null,
      },
    };
  },
});
